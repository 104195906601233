import './src/css/index.scss'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'
import '@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css'

import Splide from '@splidejs/splide'
import SplideVideo from '@splidejs/splide-extension-video'

/* eslint-disable */
// @ts-ignore
globalThis.Splide = Splide

// @ts-ignore
globalThis.SplideVideo = SplideVideo
